import { defineComponent } from 'vue'; // @ is an alias to /src

export default defineComponent({
  data: function data() {
    return {
      steps: [{
        label: '基本資料',
        lang_key: 'order.my_profile'
      }, {
        label: '提交訂單',
        lang_key: 'order.submit_the_booking'
      }, {
        label: '付款',
        lang_key: 'order.payment'
      }]
    };
  },
  props: ['active', 'size'],
  inject: ['t']
});