import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { defineComponent, inject, computed, onUnmounted } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
export default defineComponent({
  components: {
    DialogComponent: Dialog
  },
  props: {
    show: {
      type: Boolean
    },
    onClose: {
      type: Function
    },
    checkPromotionCode: {
      type: Function
    },
    modelValue: {
      type: String
    },
    errorMsg: {
      type: String
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var isMobile = inject('isMobile');
    var inputValue = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });

    var addSpaceForCardNumber = function addSpaceForCardNumber() {
      if (inputValue.value) {
        inputValue.value = inputValue.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').substr(0, 9);
      }
    };

    var handleClose = function handleClose() {
      inputValue.value = '';
      emit('update:modelValue', '');
      props.onClose && props.onClose(false);
    };

    onUnmounted(function () {
      emit('update:modelValue', '');
    });
    return {
      t: t,
      isMobile: isMobile,
      inputValue: inputValue,
      addSpaceForCardNumber: addSpaceForCardNumber,
      handleClose: handleClose
    };
  }
});