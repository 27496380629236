import { computed, Ref } from 'vue'
import { useStore } from 'vuex'
export interface useOrderDataType {
  addToCartEventPush: () => void
  checkOutEventPush: () => void
  planInfo: Ref<any>
  hotelInfo: Ref<any>
  roomInfo: Ref<any>
  durationDays: Ref<any>
}
export const useOrderData = (): useOrderDataType => {
  const store = useStore()
  const durationDays = computed(() => store.getters['form/getDuration'])
  const hotelInfo = computed(() => store.state.booking.booking_info.hotel)
  const planInfo = computed(() => store.state.booking.booking_info.plan)
  const roomInfo = computed(() => store.state.booking.booking_info.room_type)
  const addToCartEventPush = () => {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'TWD',
        add: {
          products: {
            brand: hotelInfo.value.name,
            name: roomInfo.value.name,
            price: roomInfo.value.price,
            quantity: durationDays.value
          }
        }
      }
    })
  }

  const checkOutEventPush = () => {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: {
            brand: hotelInfo.value.name,
            name: roomInfo.value.name,
            price: roomInfo.value.price,
            quantity: durationDays.value
          }
        }
      }
    })
  }

  return { addToCartEventPush, checkOutEventPush, hotelInfo, planInfo, roomInfo, durationDays }
}
