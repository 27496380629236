import { computed, defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
import BookingPriceContent from './BookingPriceContent.vue';
import Popup from '@/components/share/Popup.vue';
import Page from '@/components/share/Page.vue';
import { useStore } from 'vuex';
export default defineComponent({
  components: {
    DialogComponent: Dialog,
    BookingPriceContent: BookingPriceContent,
    Page: Page,
    Popup: Popup
  },
  props: ['isPriceDetailOpen', 'onClosePriceDetail', 'price'],
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var store = useStore();
    var booking = computed(function () {
      return store.state.booking.bookings.booking;
    });
    return {
      t: t,
      isMobile: isMobile,
      booking: booking
    };
  }
});