import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { computed, defineComponent, inject } from 'vue';
import { formatLocaleWeek, parseDateString, getDuration, numberWithCommas } from '@/utils';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    booking: {
      type: Object
    },
    price: {
      type: Object
    }
  },
  setup: function setup(props) {
    var t = inject('t');
    var store = useStore();
    var statements = computed(function () {
      return store.state.booking.bookings.statements;
    });
    var startDate = computed(function () {
      return store.state.form.search.startDate;
    });
    var endDate = computed(function () {
      return store.state.form.search.endDate;
    });
    var dateRange = computed(function () {
      var _props$booking, _props$booking2, _props$booking3, _props$booking4;

      return {
        checkIn: (_props$booking = props.booking) !== null && _props$booking !== void 0 && _props$booking.check_in ? parseDateString((_props$booking2 = props.booking) === null || _props$booking2 === void 0 ? void 0 : _props$booking2.check_in) : startDate.value,
        checkOut: (_props$booking3 = props.booking) !== null && _props$booking3 !== void 0 && _props$booking3.check_out ? parseDateString((_props$booking4 = props.booking) === null || _props$booking4 === void 0 ? void 0 : _props$booking4.check_out) : endDate.value
      };
    });
    var durationDay = computed(function () {
      return getDuration(dateRange.value.checkOut, dateRange.value.checkIn);
    });
    var bookingPrice = computed(function () {
      var _props$price, _props$booking5;

      return ((_props$price = props.price) === null || _props$price === void 0 ? void 0 : _props$price.totalPrice) || ((_props$booking5 = props.booking) === null || _props$booking5 === void 0 ? void 0 : _props$booking5.price);
    });
    return {
      t: t,
      dateRange: dateRange,
      formatLocaleWeek: formatLocaleWeek,
      durationDay: durationDay,
      numberWithCommas: numberWithCommas,
      statements: statements,
      bookingPrice: bookingPrice
    };
  }
});