import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/field/style";
import _Field from "vant/es/field";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'FieldNativeSelector',
  components: (_components = {}, _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    options: {
      type: Array
    },
    selectedValue: {
      type: String
    },
    label: {
      type: String
    },
    required: {
      type: Boolean
    },
    onSelect: {
      type: Function,
      default: function _default() {} // eslint-disable-line @typescript-eslint/no-empty-function

    },
    errorMessage: {
      type: String
    }
  },
  setup: function setup(props) {
    var handleSelect = function handleSelect(val) {
      props && props.onSelect(val);
    };

    return {
      handleSelect: handleSelect
    };
  }
});