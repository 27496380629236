import { addDays, getDay, isEqual, isAfter, isBefore } from 'date-fns'
import { computed } from 'vue'
import { useStore } from 'vuex'
export interface useNationalTravelType {
  useSubsidy: () => boolean
}
export const useNationalTravel = (): useNationalTravelType => {
  const store = useStore()
  const searchInfo = computed(() => store.state.form.search)
  const checkIn = searchInfo.value.startDate
  const checkOut = searchInfo.value.endDate
  let start = checkIn
  const end = checkOut
  const useSubsidy = () => {
    let canUse = false
    const theseDayCanUse = new Set([1, 2, 3, 4])
    while (!isEqual(start, end)) {
      if (theseDayCanUse.has(getDay(start))) {
        canUse = true
      }
      start = addDays(start, 1)
    }
    if (theseDayCanUse.has(getDay(end))) {
      canUse = true
    }

    const vacation = [
      {
        startDate: '2022-09-09T00:00:00+08:00',
        endDate: '2022-09-11T23:59:59+08:00'
      },
      {
        startDate: '2022-10-08T00:00:00+08:00',
        endDate: '2022-10-10T23:59:59+08:00'
      }
    ]
    vacation.map(({ startDate, endDate }) => {
      if (isEqual(checkIn, new Date(startDate))) {
        if (isEqual(checkOut, new Date(endDate)) || isBefore(checkOut, new Date(endDate))) {
          canUse = false
        }
      }
    })

    const LAST_DATE = new Date('2022-12-14T23:59:59+08:00')
    if (isAfter(checkIn, LAST_DATE) || isAfter(checkOut, LAST_DATE)) {
      canUse = false
    }
    return canUse
  }
  return { useSubsidy }
}
