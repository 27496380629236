import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1b83c110"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content maxWidth"
};
var _hoisted_2 = {
  class: "nav-bar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_step = _resolveComponent("step");

  var _component_pc_nav_personal = _resolveComponent("pc-nav-personal");

  var _component_pc_nav_register = _resolveComponent("pc-nav-register");

  var _component_loading_popup = _resolveComponent("loading-popup");

  return _openBlock(), _createElementBlock("header", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
    class: "logo",
    iconName: "logo"
  }), _ctx.isMobile ? (_openBlock(), _createBlock(_component_step, {
    key: 0,
    size: "small",
    active: _ctx.activeStep
  }, null, 8, ["active"])) : _createCommentVNode("", true), _createElementVNode("aside", _hoisted_2, [_ctx.isLoggedIn ? (_openBlock(), _createBlock(_component_pc_nav_personal, {
    key: 0,
    fromOrder: true
  })) : (_openBlock(), _createBlock(_component_pc_nav_register, {
    key: 1,
    onLoginModelToggle: _ctx.onLoginModelToggle
  }, null, 8, ["onLoginModelToggle"]))])]), _createVNode(_component_loading_popup)]);
}