import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-64b684ca"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "disclaime"
};
var _hoisted_2 = {
  class: "button-group"
};
var _hoisted_3 = {
  class: "button-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_disclaimer_content = _resolveComponent("disclaimer-content");

  var _component_dialog_component = _resolveComponent("dialog-component");

  var _component_page = _resolveComponent("page");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_dialog_component, {
    key: 0,
    open: _ctx.isDisclaimeOpen,
    title: _ctx.t('order.national_travel.disclaimer'),
    width: 600,
    onClose: _ctx.onCloseDisclaime
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_disclaimer_content), _createElementVNode("section", _hoisted_2, [_createElementVNode("button", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = function () {
          return _ctx.onCloseDisclaime();
        })
      }, _toDisplayString(_ctx.t('order.national_travel.understand')), 1)])];
    }),
    _: 1
  }, 8, ["open", "title", "onClose"])) : (_openBlock(), _createBlock(_component_popup, {
    key: 1,
    show: _ctx.isDisclaimeOpen,
    position: "right"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_page, {
        onClose: _ctx.onCloseDisclaime,
        title: _ctx.t('order.national_travel.disclaimer')
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_disclaimer_content), _createElementVNode("section", _hoisted_3, [_createElementVNode("button", {
            class: "btn",
            onClick: _cache[1] || (_cache[1] = function () {
              return _ctx.onCloseDisclaime();
            })
          }, _toDisplayString(_ctx.t('order.national_travel.understand')), 1)])];
        }),
        _: 1
      }, 8, ["onClose", "title"])];
    }),
    _: 1
  }, 8, ["show"]))]);
}