import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-afc874de"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "booking-price"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_booking_price_content = _resolveComponent("booking-price-content");

  var _component_dialog_component = _resolveComponent("dialog-component");

  var _component_page = _resolveComponent("page");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_dialog_component, {
    key: 0,
    open: _ctx.isPriceDetailOpen,
    title: _ctx.t('order.detailed_booking'),
    width: 600,
    onClose: _ctx.onClosePriceDetail
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_booking_price_content, {
        booking: _ctx.booking,
        price: _ctx.price
      }, null, 8, ["booking", "price"])];
    }),
    _: 1
  }, 8, ["open", "title", "onClose"])) : (_openBlock(), _createBlock(_component_popup, {
    key: 1,
    show: _ctx.isPriceDetailOpen,
    position: "right"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_page, {
        onClose: _ctx.onClosePriceDetail,
        title: _ctx.t('order.detailed_booking')
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_booking_price_content, {
            booking: _ctx.booking,
            price: _ctx.price
          }, null, 8, ["booking", "price"])];
        }),
        _: 1
      }, 8, ["onClose", "title"])];
    }),
    _: 1
  }, 8, ["show"]))]);
}