import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4c4a7af1"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "padding"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "content"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  class: "content"
};
var _hoisted_6 = {
  class: "title"
};
var _hoisted_7 = {
  class: "content"
};
var _hoisted_8 = {
  class: "title"
};
var _hoisted_9 = {
  class: "content"
};
var _hoisted_10 = {
  class: "btn-group"
};
var _hoisted_11 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dialog_component = _resolveComponent("dialog-component");

  return _openBlock(), _createBlock(_component_dialog_component, {
    open: _ctx.isCancellationPolicyShow,
    title: _ctx.t('order.cancellation_policy'),
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 600,
    onClose: _ctx.onClose
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("section", null, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('order.cancellation_policy_for_this_booking')) + ":", 1), _createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cancellationPolicy, function (policy) {
        return _openBlock(), _createElementBlock("li", {
          key: policy.long_description
        }, _toDisplayString(policy.long_description), 1);
      }), 128))])]), _createElementVNode("section", null, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('order.special_situation')) + ": ", 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('order.in_case_of_any_unexpected_situation_occurs_and_result_in_early_checkout_the_unused_reserved_nights_will_be_refunded_an_additional_cleaning_fee_will_be_charged')), 1)]), _createElementVNode("section", null, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('order.credit_card_refund_chargeback')), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('order.the_prepaid_deposit_will_be_deducted_from_the_credit_card_account_within_21_working_days_for_any_refund_meeting_the_terms_and_conditions_of_refunds_due_to_cancelled_altered_bookings_or_early_check_outs')), 1)]), _createElementVNode("section", null, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('order.invoice_issuance')), 1), _createElementVNode("ul", _hoisted_9, [_createElementVNode("li", null, _toDisplayString(_ctx.t('order.a_hotel_operator_can_issue_an_invoice_or_reciept_for_the_booking_that_is_paid_at_hotel')), 1), _createElementVNode("li", null, _toDisplayString(_ctx.t('order.if_full_payment_is_made_for_a_prepaid_stay_through_tourbobo_a_receipt_for_payment_confirmation_issued_by_tourbobo_will_be_available_in_your_booking_details_within_7_days_after_check_out')), 1)])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("button", {
        class: "main-btn",
        onClick: _ctx.onClose
      }, _toDisplayString(_ctx.t('order.understand')), 9, _hoisted_11)])];
    }),
    _: 1
  }, 8, ["open", "title", "width", "onClose"]);
}