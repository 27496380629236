import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/field/style";
import _Field from "vant/es/field";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { defineComponent, computed, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'ComFieldRadioGroup',
  components: (_components = {
    SvgIcon: SvgIcon
  }, _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _RadioGroup.name, _RadioGroup), _defineProperty(_components, _Radio.name, _Radio), _defineProperty(_components, _Cell.name, _Cell), _components),
  props: {
    options: {
      type: Array
    },
    modelValue: {
      type: [String, Boolean, Number]
    },
    label: {
      type: String
    },
    required: {
      type: Boolean
    },
    withField: {
      type: Boolean
    },
    field: {
      type: String
    },
    direction: {
      type: String
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var checked = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });
    var fieldValue = computed({
      get: function get() {
        return props.field;
      },
      set: function set(val) {
        return emit('update:field', val);
      }
    });
    return {
      t: t,
      checked: checked,
      fieldValue: fieldValue
    };
  }
});