import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-052d3374"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "stay-period"
};
var _hoisted_3 = {
  class: "info"
};
var _hoisted_4 = {
  class: "date"
};
var _hoisted_5 = {
  class: "periods"
};
var _hoisted_6 = {
  class: "info"
};
var _hoisted_7 = {
  class: "date"
};
var _hoisted_8 = {
  class: "total-price"
};
var _hoisted_9 = {
  class: "price"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('order.check_in_date')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatLocaleWeek(_ctx.dateRange.checkIn)), 1)]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('order.n_night', {
    n: _ctx.durationDay
  })), 1), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('order.check_out_date')), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatLocaleWeek(_ctx.dateRange.checkOut)), 1)])]), _createElementVNode("table", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, _toDisplayString(_ctx.t('order.item')), 1), _createElementVNode("th", null, _toDisplayString(_ctx.t('order.price')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statements, function (item) {
    return _openBlock(), _createElementBlock("tr", {
      key: item,
      class: _normalizeClass(item.item)
    }, [_createElementVNode("td", null, _toDisplayString(item.title), 1), _createElementVNode("td", null, "NT$ " + _toDisplayString(_ctx.numberWithCommas(item.price)), 1)], 2);
  }), 128))])]), _createElementVNode("table", _hoisted_8, [_createElementVNode("tr", null, [_createElementVNode("td", null, _toDisplayString(_ctx.t('order.booking_total_price')), 1), _createElementVNode("td", _hoisted_9, "NT$ " + _toDisplayString(_ctx.numberWithCommas(_ctx.bookingPrice)), 1)])])], 64);
}