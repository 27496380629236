import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "vant/es/field/style";
import _Field from "vant/es/field";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, ref, reactive, computed, watch, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'FieldTime',
  components: (_components = {
    SvgIcon: SvgIcon
  }, _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _Popover.name, _Popover), _components),
  props: ['label', 'required', 'placeholder', 'tooltip', 'modelValue', 'errorMessage'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var isMobile = inject('isMobile');
    var showPopover = ref(false);
    var success = computed(function () {
      return !props.errorMessage;
    });
    var inputValue = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });
    var timeModelValue = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });
    var time = reactive(((props === null || props === void 0 ? void 0 : props.modelValue) || '').includes(':') ? {
      hours: +props.modelValue.split(':')[0],
      minutes: +props.modelValue.split(':')[1],
      ampm: +props.modelValue.split(':')[0] >= 12 ? 'pm' : 'am'
    } : {
      hours: 0,
      minutes: 0,
      ampm: 'pm'
    });
    var show24Hours = computed(function () {
      return time.hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2
      });
    });
    var pickedTime = computed(function () {
      return props.modelValue ? "".concat(show24Hours.value, ":").concat(showMinutes.value) : '';
    });
    var showHours = computed(function () {
      var hour = time.hours >= 12 ? time.hours - 12 : time.hours;
      return hour.toLocaleString('en-US', {
        minimumIntegerDigits: 2
      });
    });
    var showMinutes = computed(function () {
      return time.minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2
      });
    });
    var showAmPm = computed(function () {
      return time.ampm.toUpperCase();
    });
    watch(function () {
      return props.modelValue || '';
    }, function (newValue) {
      time.hours = +newValue.split(':')[0];
      time.minutes = +newValue.split(':')[1];
      time.ampm = +newValue.split(':')[0] >= 12 ? 'pm' : 'am';
    });
    watch(time, function () {
      emit('update:modelValue', "".concat(show24Hours.value, ":").concat(showMinutes.value));
    });

    var add = function add(type) {
      var limit = type === 'hours' ? 23 : 59;

      if (time[type] >= limit) {
        time[type] = 0;
      } else {
        time[type]++;
      }
    };

    var minus = function minus(type) {
      var limit = type === 'hours' ? 11 : 59;

      if (time[type] === 0) {
        time[type] = limit;
      } else {
        time[type]--;
      }
    };

    var switchAmPm = function switchAmPm() {
      if (!props.modelValue) {
        emit('update:modelValue', '00:00');
      } else if (time.ampm === 'pm') {
        emit('update:modelValue', "".concat(+props.modelValue.split(':')[0] - 12, ":").concat(+props.modelValue.split(':')[1]));
      } else {
        emit('update:modelValue', "".concat(+props.modelValue.split(':')[0] + 12, ":").concat(+props.modelValue.split(':')[1]));
      }
    };

    return {
      t: t,
      isMobile: isMobile,
      success: success,
      inputValue: inputValue,
      showPopover: showPopover,
      showHours: showHours,
      showMinutes: showMinutes,
      pickedTime: pickedTime,
      add: add,
      minus: minus,
      showAmPm: showAmPm,
      switchAmPm: switchAmPm,
      timeModelValue: timeModelValue
    };
  }
});