import { computed, defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
import Dialog from '@/components/share/Dialog.vue';
export default defineComponent({
  components: {
    DialogComponent: Dialog
  },
  props: {
    isCancellationPolicyShow: {
      type: Boolean
    },
    onToggleCancellationPolicy: {
      type: Function
    }
  },
  setup: function setup(props) {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var store = useStore();
    var cancellationPolicy = computed(function () {
      return store.state.booking.booking_info.cancellation_policy;
    });

    var onClose = function onClose() {
      props.onToggleCancellationPolicy && props.onToggleCancellationPolicy(false);
    };

    return {
      t: t,
      isMobile: isMobile,
      cancellationPolicy: cancellationPolicy,
      onClose: onClose
    };
  }
});