import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-53a328db"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "card"
};
var _hoisted_2 = {
  class: "title clear-bottom"
};
var _hoisted_3 = {
  class: "sub-title"
};
var _hoisted_4 = {
  key: 0,
  class: "sub-card"
};
var _hoisted_5 = {
  key: 1,
  class: "divider"
};
var _hoisted_6 = {
  key: 2,
  class: "note"
};
var _hoisted_7 = {
  key: 0,
  class: "van-field__error-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_field_radio_group = _resolveComponent("field-radio-group");

  var _component_i18n_t = _resolveComponent("i18n-t");

  var _component_van_checkbox = _resolveComponent("van-checkbox");

  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('order.national_travel.allowance')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('order.national_travel.once_use')), 1), _createVNode(_component_field_radio_group, {
    required: "",
    options: _ctx.nationalTravelOptions,
    modelValue: _ctx.nationalTravelVal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.nationalTravelVal = $event;
    }),
    errorMessage: _ctx.errorMsg.nationalTravel
  }, null, 8, ["options", "modelValue", "errorMessage"]), _ctx.nationalTravelVal === true ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_ctx.subsidyType === 1 ? (_openBlock(), _createBlock(_component_field_radio_group, {
    key: 0,
    label: _ctx.t('order.national_travel.three_shots_of_vaccine'),
    options: _ctx.vaccineOptions,
    modelValue: _ctx.isVaccineVal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.isVaccineVal = $event;
    }),
    errorMessage: _ctx.errorMsg.isVaccine
  }, null, 8, ["label", "options", "modelValue", "errorMessage"])) : _createCommentVNode("", true), _ctx.subsidyType === 1 && _ctx.isVaccineVal !== null ? (_openBlock(), _createElementBlock("span", _hoisted_5)) : _createCommentVNode("", true), _ctx.subsidyType === 1 && _ctx.isVaccineVal !== null || _ctx.subsidyType === 2 && _ctx.nationalTravelVal ? (_openBlock(), _createElementBlock("section", _hoisted_6, [_createVNode(_component_i18n_t, {
    keypath: "order.national_travel.agree_description",
    tag: "div",
    class: "title"
  }, {
    n: _withCtx(function () {
      return [_createElementVNode("span", {
        class: "disclaimer",
        onClick: _cache[2] || (_cache[2] = //@ts-ignore
        function () {
          return _ctx.openDisclaimer && _ctx.openDisclaimer.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.t('order.national_travel.disclaimer')), 1)];
    }),
    _: 1
  }), _createVNode(_component_van_checkbox, {
    modelValue: _ctx.isAgreeDisclaimerVal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.isAgreeDisclaimerVal = $event;
    }),
    shape: "square"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.t('order.national_travel.agree_for_disclaimer')), 1)];
    }),
    _: 1
  }, 8, ["modelValue"]), _ctx.errorMsg.isAgreeDisclaimer ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_svg_icon, {
    class: "icon-hint",
    iconName: "warning"
  }), _createTextVNode(_toDisplayString(_ctx.t('order.please_tick_and_agree_the_disclaimer')), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}