import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, inject, ref } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'ErrorDialog',
  components: (_components = {
    DialogComponent: Dialog
  }, _defineProperty(_components, _CountDown.name, _CountDown), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['errorMsg', 'onClose'],
  setup: function setup() {
    var isOrderError = ref(false);
    var t = inject('t');
    var isMobile = inject('isMobile');
    return {
      t: t,
      isMobile: isMobile,
      isOrderError: isOrderError
    };
  }
});