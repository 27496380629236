import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7de5769c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "text"
};
var _hoisted_3 = {
  class: "btn-groups"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_dialog_component = _resolveComponent("dialog-component");

  return _openBlock(), _createBlock(_component_dialog_component, {
    open: Boolean(_ctx.errorMsg),
    title: _ctx.t('order.failed_submission'),
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 600,
    onClose: _ctx.onClose
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
        class: "social-link",
        iconName: "credit-card-error"
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = //@ts-ignore
        function () {
          return _ctx.onClose && _ctx.onClose.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.t('order.confirm')), 1)])])];
    }),
    _: 1
  }, 8, ["open", "title", "width", "onClose"]);
}