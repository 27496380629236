import { defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'ContryTravelDescription',
  components: {},
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    var t = inject('t');
    var store = useStore();

    var toggleHotel = function toggleHotel() {
      store.dispatch('hotel/toggleHotelPopup', 0);
    };

    return {
      toggleHotel: toggleHotel,
      t: t,
      isMobile: isMobile
    };
  }
});