import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-76f3ff44"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["value"];
var _hoisted_2 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    class: "van-field-custom",
    required: _ctx.required,
    label: _ctx.label,
    error: Boolean(_ctx.errorMessage),
    errorMessage: _ctx.errorMessage
  }, {
    input: _withCtx(function () {
      return [_createElementVNode("select", {
        class: "selector",
        value: _ctx.selectedValue,
        onChange: _cache[0] || (_cache[0] = function (e) {
          return _ctx.handleSelect(e.target.value);
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (_ref, index) {
        var label = _ref.label,
            value = _ref.value;
        return _openBlock(), _createElementBlock("option", {
          key: index,
          value: value
        }, _toDisplayString(label), 9, _hoisted_2);
      }), 128))], 40, _hoisted_1), _createVNode(_component_svg_icon, {
        class: "caret-down-icon",
        iconName: "icon_24_arrow_caret_down"
      })];
    }),
    _: 1
  }, 8, ["required", "label", "error", "errorMessage"]);
}