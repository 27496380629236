import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b39740bc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "custom-selector-label"
};
var _hoisted_2 = ["value"];
var _hoisted_3 = ["value"];
var _hoisted_4 = {
  class: "countryCode"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_selector = _resolveComponent("selector");

  var _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    class: _normalizeClass({
      'van-field-custom': true,
      'phone-field': true,
      success: !_ctx.errorMessage && _ctx.phone
    }),
    type: "tel",
    required: _ctx.required,
    label: _ctx.label,
    error: Boolean(_ctx.errorMessage),
    "error-message": !_ctx.errorMessage && _ctx.phone ? _ctx.t('order.finish') : _ctx.errorMessage,
    placeholder: _ctx.placeholder
  }, {
    input: _withCtx(function () {
      return [_ctx.isMobile ? (_openBlock(), _createElementBlock("label", _hoisted_1, [_createTextVNode(_toDisplayString(_ctx.countryCode) + " ", 1), _createVNode(_component_svg_icon, {
        class: "caret-down-icon",
        iconName: "icon_24_arrow_caret_down"
      }), _createElementVNode("select", {
        class: "custom-selector",
        value: _ctx.countryCode,
        onChange: _cache[0] || (_cache[0] = function (e) {
          return _ctx.setCountryCode(e.target.value);
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryCodes, function (_ref, index) {
        var value = _ref.value,
            label = _ref.label;
        return _openBlock(), _createElementBlock("option", {
          key: index,
          value: value
        }, _toDisplayString(value) + " " + _toDisplayString(label), 9, _hoisted_3);
      }), 128))], 40, _hoisted_2)])) : (_openBlock(), _createBlock(_component_selector, {
        key: 1,
        showValue: "",
        options: _ctx.countryCodes,
        selectedValue: _ctx.countryCode,
        onSelect: _ctx.setCountryCode,
        searchPlaceholder: _ctx.t('order.please_enter_or_pick_a_nation')
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.countryCode), 1), _createVNode(_component_svg_icon, {
            class: "caret-down-icon",
            iconName: "icon_24_arrow_caret_down"
          })])];
        }),
        _: 1
      }, 8, ["options", "selectedValue", "onSelect", "searchPlaceholder"])), _withDirectives(_createElementVNode("input", {
        type: "tel",
        class: "input",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.phone = $event;
        }),
        placeholder: "900 000 000"
      }, null, 512), [[_vModelText, _ctx.phone]])];
    }),
    "error-message": _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        iconName: !_ctx.errorMessage ? 'icon_24_hint_ok' : 'icon_24_hint_attention',
        class: "hint-icon"
      }, null, 8, ["iconName"]), _createTextVNode(_toDisplayString(!_ctx.errorMessage ? _ctx.t('order.finish') : _ctx.errorMessage), 1)];
    }),
    _: 1
  }, 8, ["class", "required", "label", "error", "error-message", "placeholder"]);
}