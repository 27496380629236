import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a81d9ba"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "input-content"
};
var _hoisted_3 = {
  key: 0,
  class: "error-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_dialog_component = _resolveComponent("dialog-component");

  var _component_promotion_confirm_popup = _resolveComponent("promotion-confirm-popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_dialog_component, {
    open: _ctx.open,
    title: "優惠碼",
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 400,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass({
          input: true,
          error: _ctx.hasError
        })
      }, [_withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.inputValue = $event;
        })
      }, null, 512), [[_vModelText, _ctx.inputValue]]), _createVNode(_component_svg_icon, {
        class: "del-icon",
        iconName: "icon-24-delettext",
        onClick: _cache[1] || (_cache[1] = function () {
          return _ctx.emit('update:modelValue', '');
        })
      })], 2), _createElementVNode("button", {
        class: "btn",
        onClick: _cache[2] || (_cache[2] = function () {
          return _ctx.checkPromotionCode();
        })
      }, "確定")]), _ctx.hasError ? (_openBlock(), _createElementBlock("div", _hoisted_3, "此優惠碼無效")) : _createCommentVNode("", true)])];
    }),
    _: 1
  }, 8, ["open", "width", "onClose"]), _createVNode(_component_promotion_confirm_popup, {
    open: _ctx.isConfirmPopup,
    onClose: _ctx.onConfirmClose,
    getBookingPrice: _ctx.getBookingPrice
  }, null, 8, ["open", "onClose", "getBookingPrice"])], 64);
}