import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";

var _components;

import "core-js/modules/es.function.name.js";
import { computed, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import FieldRadioGroup from '@/components/order/FieldRadioGroup.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'OrderForm',
  components: (_components = {
    FieldRadioGroup: FieldRadioGroup
  }, _defineProperty(_components, _Switch.name, _Switch), _defineProperty(_components, _Checkbox.name, _Checkbox), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  inject: ['t'],
  props: ['nationalTravel', 'isVaccine', 'isAgreeDisclaimer', 'errorMsg', 'openDisclaimer', 'checkPromotionCode', 'promotionCode', 'removeCouponCode'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var nationalTravelOptions = computed(function () {
      return store.state.options.nationalTravelOptions;
    });
    var vaccineOptions = computed(function () {
      return store.state.options.vaccineOptions;
    });
    var subsidyType = computed(function () {
      return store.state.booking.booking_info.hotel.subsidy_type;
    });
    var travelPromotions = computed(function () {
      return store.state.booking.booking_info.travel_promotions;
    });
    var nationalTravelVal = computed({
      get: function get() {
        return props.nationalTravel;
      },
      set: function set(val) {
        return emit('update:nationalTravel', val);
      }
    });
    var isVaccineVal = computed({
      get: function get() {
        return props.isVaccine;
      },
      set: function set(val) {
        return emit('update:isVaccine', val);
      }
    });
    var isAgreeDisclaimerVal = computed({
      get: function get() {
        return props.isAgreeDisclaimer;
      },
      set: function set(val) {
        return emit('update:isAgreeDisclaimer', val);
      }
    });
    var promotionCodeVal = computed({
      get: function get() {
        return props.promotionCode;
      },
      set: function set(val) {
        return emit('update:promotionCode', val);
      }
    });
    watch([isVaccineVal, isAgreeDisclaimerVal], function () {
      if (isAgreeDisclaimerVal.value) {
        if (isVaccineVal.value === true) {
          if (travelPromotions.value.indexOf('tbbgs1300') !== -1) {
            promotionCodeVal.value = 'tbbgs1300';
            props.checkPromotionCode();
          }
        } else if (isVaccineVal.value === false) {
          if (travelPromotions.value.indexOf('tbbgs800') !== -1) {
            promotionCodeVal.value = 'tbbgs800';
            props.checkPromotionCode();
          }
        } else {
          promotionCodeVal.value = '';
        }
      } else {
        promotionCodeVal.value = '';
      }
    });
    watch(nationalTravelVal, function () {
      if (nationalTravelVal.value === false) {
        isVaccineVal.value = null;
        isAgreeDisclaimerVal.value = null;
        promotionCodeVal.value = '';
      } else {
        if (travelPromotions.value.indexOf('tbbgs1300') !== -1 && isAgreeDisclaimerVal.value) {
          promotionCodeVal.value = 'tbbgs1300';
          props.checkPromotionCode();
        }
      }
    });
    return {
      isVaccineVal: isVaccineVal,
      isAgreeDisclaimerVal: isAgreeDisclaimerVal,
      nationalTravelVal: nationalTravelVal,
      nationalTravelOptions: nationalTravelOptions,
      vaccineOptions: vaccineOptions,
      subsidyType: subsidyType
    };
  }
});