import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "core-js/modules/es.function.name.js";
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'FieldCellphone',
  components: _defineProperty({
    SvgIcon: SvgIcon
  }, _Field.name, _Field),
  inject: ['t', 'isMobile'],
  props: ['label', 'required', 'countryCode', 'setCountryCode', 'modelValue', 'errorMessage', 'placeholder'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var countryCodes = computed(function () {
      return store.state.options.countryCodes;
    });
    var phone = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });

    if (countryCodes.value.length === 0) {
      store.dispatch('options/getAllCountryCodes');
    }

    return {
      countryCodes: countryCodes,
      phone: phone
    };
  }
});