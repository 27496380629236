import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_date_picker = _resolveComponent("el-date-picker");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    class: _normalizeClass({
      'van-field-custom': true,
      'van-cell--small': true,
      success: _ctx.showSuccess && _ctx.success && _ctx.inputValue
    }),
    required: _ctx.required,
    label: _ctx.label,
    error: Boolean(_ctx.errorMessage),
    errorMessage: _ctx.showSuccess && _ctx.success && _ctx.inputValue ? _ctx.t('order.finish') : _ctx.errorMessage,
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.inputValue = $event;
    })
  }, {
    input: _withCtx(function () {
      return [_ctx.isMobile ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        type: "date",
        class: "custom-date-picker",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.date = $event;
        })
      }, null, 512)), [[_vModelText, _ctx.date]]) : (_openBlock(), _createBlock(_component_el_date_picker, {
        key: 1,
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.date = $event;
        }),
        type: "date",
        placeholder: _ctx.placeholder,
        "value-format": "YYYY-MM-DD",
        clearable: false
      }, null, 8, ["modelValue", "placeholder"])), _createVNode(_component_svg_icon, {
        class: "caret-down-icon",
        iconName: "icon_24_arrow_caret_down"
      })];
    }),
    "error-message": _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        iconName: _ctx.success ? 'icon_24_hint_ok' : 'icon_24_hint_attention',
        class: "hint-icon"
      }, null, 8, ["iconName"]), _createTextVNode(_toDisplayString(_ctx.success ? _ctx.t('order.finish') : _ctx.errorMessage), 1)];
    }),
    _: 1
  }, 8, ["class", "required", "label", "error", "errorMessage", "modelValue"]);
}