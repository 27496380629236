import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8606f638"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "coupon-ticket"
};
var _hoisted_2 = {
  class: "coupon-logo-section"
};
var _hoisted_3 = {
  class: "coupon-text"
};
var _hoisted_4 = {
  class: "text"
};
var _hoisted_5 = {
  class: "coupon-name"
};
var _hoisted_6 = {
  class: "coupon-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_svg_icon, {
    class: "coupon-logo",
    iconName: "coupon-logo"
  })]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.code), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.couponDescription), 1)]), _ctx.code !== 'tbbgs1300' && _ctx.code !== 'tbbgs800' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "delete",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.removeCouponCode && _ctx.removeCouponCode.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('order.delete')), 1)) : _createCommentVNode("", true)])]);
}