import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "core-js/modules/es.function.name.js";
import { defineComponent, ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { numberWithCommas } from '@/utils';
export default defineComponent({
  name: 'ExtraPurchase',
  components: _defineProperty({
    SvgIcon: SvgIcon
  }, _Stepper.name, _Stepper),
  props: ['modelValue', 'earlyCheckIn'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var isExtrasPeopleChecked = ref(props.modelValue.adults || props.modelValue.children);
    var t = inject('t');
    var isMobile = inject('isMobile');
    var steppers = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });
    var isEarlyCheckIn = computed({
      get: function get() {
        return props.earlyCheckIn;
      },
      set: function set(val) {
        return emit('update:early-check-in', val);
      }
    });
    var stepperWidth = computed(function () {
      return isMobile ? 20 : 24;
    });
    var extrasPlan = computed(function () {
      return store.state.booking.booking_info.room_type.extras;
    });
    var checkInTime = computed(function () {
      return store.state.booking.booking_info.hotel.check_in;
    });
    var earlyCheckInPrice = computed(function () {
      return store.state.booking.booking_info.hotel.early_check_in_fee;
    });

    var handleToggleExtrasPeople = function handleToggleExtrasPeople() {
      if (isExtrasPeopleChecked.value) {
        isExtrasPeopleChecked.value = false;
      } else {
        isExtrasPeopleChecked.value = true;
      }
    };

    var handleToggleEarlyCheckIn = function handleToggleEarlyCheckIn() {
      if (isEarlyCheckIn.value) {
        isEarlyCheckIn.value = false;
      } else {
        isEarlyCheckIn.value = true;
      }
    };

    return {
      t: t,
      // extra people
      isExtrasPeopleChecked: isExtrasPeopleChecked,
      handleToggleExtrasPeople: handleToggleExtrasPeople,
      // early check in
      isEarlyCheckIn: isEarlyCheckIn,
      handleToggleEarlyCheckIn: handleToggleEarlyCheckIn,
      stepperWidth: stepperWidth,
      extrasPlan: extrasPlan,
      numberWithCommas: numberWithCommas,
      checkInTime: checkInTime,
      earlyCheckInPrice: earlyCheckInPrice,
      steppers: steppers
    };
  }
});