import { defineComponent } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Step from '@/components/order/Steps.vue';
import LoadingPopup from '@/components/share/LoadingPopup.vue';
import PcNavRegister from '@/components/navbar/PcNavRegister.vue';
import PcNavPersonal from '@/components/navbar/PcNavPersonal.vue';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon,
    Step: Step,
    LoadingPopup: LoadingPopup,
    PcNavRegister: PcNavRegister,
    PcNavPersonal: PcNavPersonal
  },
  props: ['activeStep', 'onLoginModelToggle'],
  inject: ['isMobile'],
  setup: function setup() {
    var isLoggedIn = useAuthStore().isLoggedIn;
    return {
      isLoggedIn: isLoggedIn
    };
  }
});