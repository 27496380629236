import { defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
export default defineComponent({
  name: 'PromotionConfirmPopup',
  components: {
    DialogComponent: Dialog
  },
  props: {
    open: {
      type: Boolean
    },
    onClose: {
      type: Function
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var isMobile = inject('isMobile');
    var onLoginModelToggle = inject('onLoginModelToggle');

    var onLoginClick = function onLoginClick() {
      if (props !== null && props !== void 0 && props.onClose) props.onClose();
      if (onLoginModelToggle) onLoginModelToggle();
    };

    return {
      t: t,
      isMobile: isMobile,
      emit: emit,
      onLoginClick: onLoginClick
    };
  }
});