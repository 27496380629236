import _slicedToArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import format from 'date-fns/format';
import { zhTW } from 'date-fns/locale';
import { DATEWEEK_FORMAT } from '@/constants';
import { numberWithCommas } from '@/utils';
import { useOrderData } from '@/hook/useOrderData';
import CancellationStep from './CancellationStep.vue';
import { useAuthStore } from '@/hook/useAuthStore';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: {
    CancellationStep: CancellationStep,
    SvgIcon: SvgIcon
  },
  name: 'OrderFormInfo',
  inject: ['t', 'isMobile'],
  props: ['price', 'useNationalSubsidy', 'onOpenDiscountDialog', 'rewardPointCount', 'onPromotionPopupOpen', 'promotionCode'],
  setup: function setup() {
    var store = useStore();
    var isPriceDetailOpen = ref(false);
    var searchInfo = computed(function () {
      return store.state.form.search;
    });
    var checkIn = format(searchInfo.value.startDate, DATEWEEK_FORMAT, {
      locale: zhTW
    });
    var checkOut = format(searchInfo.value.endDate, DATEWEEK_FORMAT, {
      locale: zhTW
    });
    var durationDays = computed(function () {
      return store.getters['form/getDuration'];
    });
    var hotelInfo = computed(function () {
      return store.state.booking.booking_info.hotel;
    });
    var planInfo = computed(function () {
      return store.state.booking.booking_info.plan;
    });
    var roomInfo = computed(function () {
      return store.state.booking.booking_info.room_type;
    });
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var isLoggedIn = useAuthStore().isLoggedIn;
    var isSend = false;

    var _useOrderData = useOrderData(),
        addToCartEventPush = _useOrderData.addToCartEventPush;

    watch([hotelInfo, roomInfo], function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          newA = _ref2[0],
          newB = _ref2[1];

      if (newA && newB && !isSend) {
        addToCartEventPush();
        isSend = true;
      }
    });

    var onOpenPriceDetail = function onOpenPriceDetail() {
      isPriceDetailOpen.value = true;
    };

    var onClosePriceDetail = function onClosePriceDetail() {
      isPriceDetailOpen.value = false;
    };

    onMounted(function () {
      console.log('isLoggedIn', isLoggedIn);

      if (isLoggedIn.value) {
        store.dispatch('user/getRewardCount');
      }
    });
    return {
      isLoggedIn: isLoggedIn,
      isPriceDetailOpen: isPriceDetailOpen,
      onOpenPriceDetail: onOpenPriceDetail,
      onClosePriceDetail: onClosePriceDetail,
      searchInfo: searchInfo,
      checkIn: checkIn,
      checkOut: checkOut,
      durationDays: durationDays,
      hotelInfo: hotelInfo,
      planInfo: planInfo,
      roomInfo: roomInfo,
      numberWithCommas: numberWithCommas,
      businessType: businessType
    };
  }
});