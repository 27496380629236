import { computed, defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
import { numberWithCommas } from '@/utils';
import { useStore } from 'vuex';
import TooltipIcon from '@/components/share/TooltipIcon.vue';
export default defineComponent({
  name: 'OrderDiscountPrice',
  components: {
    DialogComponent: Dialog,
    TooltipIcon: TooltipIcon
  },
  props: ['open', 'onClose', 'price', 'rewardPointCount', 'handleUpdateInput'],
  setup: function setup(props) {
    var store = useStore();
    var t = inject('t');
    var isMobile = inject('isMobile');
    var rewardCount = computed(function () {
      return store.state.user.rewardCount;
    });
    var remainPoints = computed(function () {
      return rewardCount.value.count - props.rewardPointCount < 0 ? 0 : rewardCount.value.count - props.rewardPointCount;
    });
    return {
      t: t,
      isMobile: isMobile,
      numberWithCommas: numberWithCommas,
      rewardCount: rewardCount,
      remainPoints: remainPoints
    };
  }
});