import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a0cdc9ba"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "circle-text"
};
var _hoisted_2 = {
  class: "label-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("ul", {
    class: _normalizeClass({
      steps: true,
      small: _ctx.size === 'small'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, function (step, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: _normalizeClass({
        step: true,
        'step-item--active': index + 1 <= _ctx.active
      })
    }, [_createElementVNode("div", _hoisted_1, _toDisplayString(index + 1), 1), _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t(step.lang_key)), 1)], 2);
  }), 128))], 2);
}