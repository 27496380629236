import { computed, defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    verticalStyle: {
      type: Boolean,
      default: false
    },
    cancellationPolicy: {}
  },
  setup: function setup(props) {
    var t = inject('t');
    var store = useStore();
    var policy = computed(function () {
      return props.cancellationPolicy || store.state.booking.booking_info.cancellation_policy;
    });
    var colors = ['blue', 'orange', 'red'];
    return {
      t: t,
      colors: colors,
      policy: policy
    };
  }
});